<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <!-- <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    /> -->

    <CHeaderBrand class="mx-auto d-lg-none">
      <router-link to="/dashboard" class="pl-0">
        <img
          class="c-sidebar-brand-full float-left"
          name="logo"
          size="custom-size"
          :height="45"
          viewBox="0 0 556 134"
          src="/cwasa-hd-logo.png"
          :alt="appTitle"
        />
      </router-link>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
<!--      <CHeaderNavItem class="px-3">
        <div class="searchContainer">
          &lt;!&ndash; <i class="cil-search"></i> &ndash;&gt;
          <form v-on:submit.prevent="handleSearch(search)">
            <button class="btn btn-default">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                role="img"
                class="c-icon c-icon-custom-size"
                width="40"
                height="20"
              >
                <path
                  fill="var(&#45;&#45;ci-primary-color, currentColor)"
                  d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"
                  class="ci-primary"
                ></path>
              </svg>
            </button>

            <input
              @keyup="autoSuggestion(search)"
              class="searchBox"
              type="text"
              name="search"
              v-model="search"
              placeholder="Search..."
            />
          </form>
          <div class="panel-body bg-white custom-autosearch">
            <div class="panel-footer" v-if="autoSearchData.length">
              <ul class="list-group">
                <div v-if="autoSearchData[0].length">
                  <li class="custom-header">Customer</li>
                  <li
                    @click="
                      selectSearch(data.mobile_number || data.account_number)
                    "
                    class="list-group-item"
                    v-for="data in autoSearchData[0]"
                    :key="data.id"
                  >
                    {{ data.mobile_number || data.account_number }}
                  </li>
                </div>
                <div v-if="autoSearchData[1].length">
                  <li class="custom-header">Meter</li>
                  <li
                    @click="selectSearch(data.hardware_serial_no)"
                    class="list-group-item"
                    v-for="data in autoSearchData[1]"
                    :key="data.id"
                  >
                    {{
                      data.hardware_serial_no ? data.hardware_serial_no : "N/A"
                    }}
                  </li>
                </div>
                <div v-if="autoSearchData[2].length">
                  <li class="custom-header">Bill</li>
                  <li
                    @click="selectSearch(data.bill_number)"
                    class="list-group-item"
                    v-for="data in autoSearchData[2]"
                    :key="data.id"
                  >
                    {{ data.bill_number }}
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        &lt;!&ndash; <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink> &ndash;&gt;
      </CHeaderNavItem>-->
      <CHeaderNavItem class="px-3">
        <!-- <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <!-- <CHeaderNavLink>
          Settings
        </CHeaderNavLink> -->
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <!-- <CModal
          title="Notifications"
          :show.sync="showNotificationPopup"
          color="primary"
          class="custom-close-button hide-footer popup-container"
        >
          <ul>
            <li
              class="border-bottom p-2"
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <a
                href="javascript:void(0)"
                @click="viewNotificationInfo(notification)"
                :class="notification.is_read ? '' : 'unread'"
                >{{ notification.description }}</a
              >

              <p>
                <span class="r-time">{{
                  notification.created_at | relativeTime
                }}</span>
              </p>
            </li>
            <a
              href="javascript:void(0)"
              v-if="notificationCount > 0"
              class="mark-as-read p-2"
              @click="markAllAsRead"
              >Mark all as read</a
            >
          </ul>
        </CModal> -->
        <!-- @click.stop="showAllOptions" -->
        <div
          class="popover__wrapper"
          :class="showNotificationPopup ? 'show' : ''"
        >
          <CLink @click="getRecentNotifications">
            <CHeaderNavLink>
              <CIcon name="cil-bell" />
              <sup v-if="notificationCount && notificationCount != '0'">{{
                notificationCount > 99
                  ? "99+"
                  : notificationCount > 0
                  ? notificationCount
                  : ""
              }}</sup>
            </CHeaderNavLink>
          </CLink>
          <!-- <a href="javascript:void(0)">
        <svg
          data-v-35c89fa8=""
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-settings"
        >
          <circle data-v-35c89fa8="" cx="12" cy="12" r="3"></circle>
          <path
            data-v-35c89fa8=""
            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
          ></path>
        </svg>
      </a> -->

          <div
            class="popover__content"
            v-if="showAutocomplete"
            v-click-outside="hide"
          >
            <a class="popover__message pl-1" v-if="isActive == true"
              >Notifications</a
            >

            <div class="d-flex justify-content-center align-items-center">
              <CSpinner color="info" v-if="isActive == false" />
            </div>

            <ul class="p-0 pl-1" v-if="isActive == true">
              <li
                class="border-bottom p-1"
                v-for="(notification, index) in notifications"
                :key="index"
              >
                <a
                  href="javascript:void(0)"
                  @click="viewNotificationInfo(notification)"
                  :class="notification.is_read ? '' : 'unread'"
                  >{{ notification.description }}</a
                >

                <p>
                  <span class="r-time">{{
                    notification.created_at | relativeTime
                  }}</span>
                </p>
              </li>

               <a
                @click="showNotificationPopup = false; $router.push('/notifications')"
                href="javascript:void(0)"
                class="mark-as-read p-2"
                >View All</a
              >

              <a
                href="javascript:void(0)"
                v-if="notificationCount > 0"
                class="mark-as-read p-2 text-right"
                @click="markAllAsRead"
                >Mark all as read</a
              >
            </ul>
          </div>
        </div>

        <!-- <CLink @click="getRecentNotifications">
          <CHeaderNavLink>
            <CIcon name="cil-bell" />
            <sup v-if="notificationCount">{{
              notificationCount > 99 ? "99+" : notificationCount
            }}</sup>
          </CHeaderNavLink>
        </CLink> -->
      </CHeaderNavItem>
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <!-- <CBreadcrumbRouter class="border-0 mb-0" /> -->
      <Breadcurmb/>
    </CSubheader>



    <CToaster v-if="toastr" :autohide="3000">
      <template>
        <CToast :show="showToast" position="bottom-right">
          {{ toastr }}
        </CToast>
      </template>
    </CToaster>

    <!-- <div class="callout">
      <button @click.stop="showAllOptions" class="button secondary">Click me</button>

      <div class="callout" v-if="showAutocomplete" v-click-outside="hide">
        <p>
          This is the Inside. You can click inside of here and nothing happens.
          You close with the button below, or by clicking outside the box.
        </p>

        <p>
          If you click outside of this box, the event defined via the
          <i>v-click-outside</i> directive will be emitted.
        </p>

        <p>
          Caveat: The Event that makes this box show ("Click me") has to use the
          .stop modifier, otherwise the event set up by
          <i>v-click-outside</i> will catch it, and $emit the custom event
          prematurely
        </p>

        <button class="button" @click="hide">Close</button>
      </div>
    </div> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import Breadcurmb from "./Breadcurmb.vue";
import Pusher from "pusher-js";
import { mapGetters } from "vuex";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    Breadcurmb
  },
  data() {
    return {
      appTitle: process.env.VUE_APP_NAME,
      toastr: "",
      showToast: false,
      // notificationCount: 0,
      showNotificationPopup: false,
      notifications: [],
      search: "",
      isActive: false,
      showAutocomplete: false,
    };
  },

  methods: {
    showAllOptions() {
      this.showAutocomplete = !this.showAutocomplete;
    },
    hide() {
      this.showAutocomplete = false;
    },
    selectSearch(data) {
      this.search = data;
      this.handleSearch(data);
      this.$store.state.Dashboard.autoSearchData = [];
    },
    // getNotificationCount() {
    //   this.axios
    //     .get(`/admin/users/unread-notifications-count`)
    //     .then((res) => {
    //       this.notificationCount = res.data.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getRecentNotifications() {
      this.showNotificationPopup = !this.showNotificationPopup;
      if (!this.showNotificationPopup) {
        return false;
      }
      this.isActive = false;

      this.axios
        .get(`/admin/users/notifications`)
        .then((res) => {
          this.isActive = true;
          this.notifications = res.data.data;
          this.showAllOptions();
        })
        .catch((err) => {
          this.isActive = false;
          console.log(err);
        });
    },

    markAllAsRead() {
      // this.axios
      //   .put(`/admin/user-notifications/mark-all-as-read`, {})
      //   .then(() => {
      //     this.notifications = _.map(this.notifications, (item) => {
      //       item.is_read = true;
      //       return item;
      //     });
      //     this.notificationCount = 0;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      this.$store.dispatch("Notification/markAllAsRead");
    },

    viewNotificationInfo(notification) {
      this.axios
        .put(`/admin/user-notifications/${notification.id}/mark-as-read`)
        .then(() => {
          this.showNotificationPopup = false;
          if (this.notificationCount && !notification.is_read) {
            // this.notificationCount -= 1;
             this.$store.dispatch("Notification/updateNotificationCount", 'sub');
          }
          if (notification.type === "meter_error") {
            this.$router.push({
              name: "Error Logs",
              params: { id: notification.notificationable.id },
            });
          } else if (notification.type === "water_vehicle_request") {
            this.$router.push({
              name: "Water Vehicle Detail",
              params: { id: notification.notificationable.id },
            });
          } else if (notification.type === "customer_support") {
            this.$router.push({
              name: "CustomerSupportView",
              params: { id: notification.notificationable.id },
            });
          } else if (notification.type === "task") {
            this.$router.push({
              name: "TaskView",
              params: { id: notification.notificationable.id },
            });
          }  else {
            this.$router.push({
              name: "MeterView",
              params: { id: notification.notificationable.id },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    subscribe() {
      Pusher.logToConsole = false;
      let pusher = new Pusher(`${process.env.VUE_APP_PUSHER_API_KEY}`, {
        cluster: "ap2",
        encrypted: true,
      });
      pusher.subscribe("team-notifications-user." + this.$auth.user().id);
      pusher.bind("App\\Events\\TeamNotificationEvent", (data) => {
        // this.notificationCount += 1;
        this.$store.dispatch("Notification/updateNotificationCount", 'add');
        this.notifications.unshift(data.userNotification);
        this.showToast = true;
        // this.$toastr.s(
        //   "info",
        //   data.userNotification.description,
        //   "New Notification!"
        // );
        new Audio(require("@/assets/sounds/me-too.ogg")).play();
      });
    },
    handleSearch(search) {
      if (search) {
        this.$store.dispatch("Dashboard/dashboardSearch", search);
      }
    },
    autoSuggestion(search) {
      if (search) {
        this.$store.dispatch("Dashboard/autoSuggestion", search);
      }
    },
  },
  //  directives: {
  //   outside: {
  //     priority: 700,
  //     bind() {
  //       let self = this;
  //       this.event = function (event) {
  //         console.log("emitting event");
  //         self.vm.$emit(self.expression, event);
  //       };
  //       this.el.addEventListener("click", this.stopProp);
  //       document.body.addEventListener("click", this.event);
  //     },

  //     unbind() {
  //       console.log("unbind");
  //       this.el.removeEventListener("click", this.stopProp);
  //       document.body.removeEventListener("click", this.event);
  //     },
  //     stopProp(event) {
  //       event.stopPropagation();
  //     },
  //   },
  // },
  events: {
    closeEvent() {
      console.log("close event called");
      this.hide();
    },
  },
  mounted() {
    // this.getNotificationCount();
    this.$store.dispatch("Notification/getNotificationCount");
    this.subscribe();
  },
  computed: {
    ...mapGetters("Dashboard", ["autoSearchData"]),
    ...mapGetters("Notification", ["notificationCount"]),
  },
  filters: {
    relativeTime: function (value) {
      if (!value) return "";
      return moment(value).fromNow();
    },
  },
};
</script>
<style lang="scss">
.c-header {
  .c-subheader {
    min-height: 45px;
  }
}
</style>
<style scoped>
.custom-autosearch {
  position: fixed;
  z-index: 1111;
  margin: 2px;
  width: 306px;
}
ul li {
  list-style: none;
}
sup {
  color: #fff;
  font-size: 10px;
  top: -13px;
  left: -8px;
  background-color: #f46a6a;
  display: block;
  /* width: 29px; */
  height: 20px;
  padding: 9px 6px 6px;
  border-radius: 50%;
  min-width: 18px;
  text-align: center;
}
.searchContainer {
  flex: 1 1 300px;
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f1f1f1;
  width: 320px;
  display: block;
  margin: auto;
}

.searchIcon {
  padding: 0.5rem;
}

.searchBox {
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  flex: 1;
  width: 80%;
  outline: none;
  background-color: #f1f1f1;
}

.searchButton {
  background: #538ac5;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 0;
}
.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 1.6rem;
  height: 1.6rem;
}
.mark-as-read {
  display: inline-block;
  margin-right: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #ff8c00 !important;
}

.unread {
  color: #000;
  font-weight: 600;
}

.r-time {
  display: block;
  font-size: 11px;
  color: #9e9e9e;
  margin-top: 6px;
}
a {
  color: #000;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
}
</style>

<style lang="scss" scoped>
.popover__wrapper {
  width: 80px;
  a {
    color: #000;
    text-decoration: none;
  }

  .popover__content {
    opacity: 1;
    visibility: hidden;
    position: absolute;
    left: -130px;
    transform: translate(0, 10px);
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    ul {
      height: 300px;
      width: auto;
      li {
        color: #000;
        padding: 5px 0px 0px 5px;
        font-size: 15px;
        border-bottom: 1px solid rgba(204, 204, 204, 0.4);
        line-height: 1;
        list-style: none;
        a {
          color: #000;
          text-decoration: none;
          position: sticky;
        }
      }
    }
  }

  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}
.popover__wrapper {
  &.show {
    .popover__content {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      z-index: 10;
      color: white;
      opacity: 1;
      visibility: visible;
      // transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      // left: 50%;
      // transform: translate(-50%, 5px);
      width: 270px;
      max-height: 420px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.popover__message {
  text-align: center;
  font-size: 16px;
  color: #2a49ff !important;
}
.custom-header {
  color: #f6941e;
  font-size: 13px;
  cursor: not-allowed;
  pointer-events: none;
  padding: 0.75rem 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}
</style>





