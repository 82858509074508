<template>
  <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" v-for="(breadcrumb, key) in breadcrumbs" :key="key" :class="breadcrumb.isActive?'active':''">     
      <span v-if="breadcrumb.isActive">{{ breadcrumb.text }}</span>
      <router-link :to="breadcrumb.path" v-else>{{ breadcrumb.text }}</router-link>
    </li>   
  </ol>
</nav>

</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "Breadcurmb",
  computed: {
    ...mapGetters('Config', ['breadcrumbs'])
  }
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  margin-bottom: 0;
  border: none;
}
</style>